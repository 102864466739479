import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class Regions extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchRegions() {
    return this.get('/api/regions')
  }

  createNewRegion(body) {
    return this.post('/api/regions', body)
  }

  fetchOneRegion(id) {
    return this.get(`/api/regions/${id}`)
  }

  updateRegion(id, body) {
    return this.post(`/api/regions/${id}?_method=PUT`, body)
  }

  deleteRegion(id) {
    return this.delete(`/api/regions/${id}`)
  }

  updateRegionStatus(id) {
    return this.get(`/api/regions/${id}/is_active`)
  }

}

export default Regions

