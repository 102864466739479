import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import pages from "@/router/routes/pages";
import categories from "@/router/routes/categories";
import regions from "@/router/routes/regions";
import faqs from "@/router/routes/faqs";
import locations from "@/router/routes/locations";
import feedbacks from "@/router/routes/feedbacks";
import messages from "@/router/routes/messages";
import subcategories from "@/router/routes/subcategories";
import faqCategories from "@/router/routes/faqCategories";
import chatLink from "@/router/routes/chatLink";
import SendPromo from "@/router/routes/send-promo";
import AdminsList from "@/router/routes/admins-list";
import Disclaimer from "@/router/routes/disclaimer";
import Settings from "@/router/routes/settings";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {
            x: 0,
            y: 0,
        }
    },
    routes: [
        {
            path: '/',
            redirect: {name: 'categories'},
        },
        ...pages,
        ...categories,
        ...regions,
        ...faqs,
        ...locations,
        ...feedbacks,
        ...messages,
        ...subcategories,
        ...faqCategories,
        ...chatLink,
        ...SendPromo,
        ...AdminsList,
        ...Disclaimer,
        ...Settings,
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})

router.beforeEach((to, from, next) => {
    const AUTH_TOKEN = localStorage.getItem('accessToken')
    if (to.name === 'auth-login') return next()
    if (AUTH_TOKEN) {
        if (to.path === '/') {
            return next({name: 'intro'})
        } else {
            return next()
        }
    } else {
        // return next({ name: "home" })
        return next({name: 'auth-login'})
    }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
