import {axiosInstance} from '@/services/core/base'
import Core from '@/services/core'

class chatLink extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchChatLink() {
    return this.get('/api/chats')
  }

  updateChatLink(body) {
    return this.post('/api/chats?_method=PUT', body)
  }

  updateChatLinkStatus() {
    return this.get('/api/hotLine/is_active_chat_with_operator')
  }

}

export default chatLink

