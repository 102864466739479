import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class Categories extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchCategories() {
    return this.get('/api/categories')
  }

  createNewCategory(body) {
    return this.post('/api/categories', body)
  }

  fetchOneCategory(id) {
    return this.get(`/api/categories/${id}`)
  }

  updateCategoryStatus(id) {
    return this.get(`/api/categories/${id}/is_active`)
  }

  updateCategory(id, body) {
    return this.post(`/api/categories/${id}?_method=PUT`, body)
  }

  deleteCategory(id) {
    return this.delete(`/api/categories/${id}`)
  }

}

export default Categories

