import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class Feedbacks extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchFeedbacks() {
    return this.get('/api/feedbacks')
  }

  updateFeedbackStatus(id) {
    return this.get(`/api/feedbacks/${id}/is_active`)
  }

  createFeedback(body) {
    return this.post('/api/feedbacks', body)
  }

  fetchOneFeedback(id) {
    return this.get(`/api/feedbacks/${id}`)
  }

  updateFeedback(id, body) {
    return this.post(`/api/feedbacks/${id}?_method=PUT`, body)
  }

  deleteFeedback(id) {
    return this.delete(`/api/feedbacks/${id}`)
  }

  switchHotLine() {
    return this.get('/api/hotLine/is_active')
  }

}

export default Feedbacks

