import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core/index'

class Settings extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchSettings() {
    return this.get('/api/user')
  }

  setUserDetails(body) {
    return this.put('/api/user', body)
  }

}

export default Settings

