import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class Messages extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchMessages() {
    return this.get('/api/messages')
  }

  fetchOneMessage(id) {
    return this.get(`/api/messages/${id}`)
  }

  updateMessageStatus(id) {
    return this.get(`/api/messages/set_status/${id}`)
  }

}

export default Messages

