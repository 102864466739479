import {axiosInstance} from '@/services/core/base'
import Core from '@/services/core'

class AdminsList extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchAdmins() {
    return this.get('/api/promos/getAccount')
  }

  fetchOneAdmin(id) {
    return this.get(`/api/promos/getAccount/${id}`)
  }

  updateAdmin(id, params) {
    return this.post(`/api/promos/setAccount/${id}?_method=PUT`, params)
  }

}

export default AdminsList

