import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class Faqs extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchFaqs() {
    return this.get('/api/faqs')
  }

  createNewFaq(body) {
    return this.post('/api/faqs', body)
  }

  fetchOneFaq(id) {
    return this.get(`/api/faqs/${id}`)
  }

  updateFaq(id, body) {
    return this.post(`/api/faqs/${id}`, body)
  }

  deleteFaq(id) {
    return this.delete(`/api/faqs/${id}`)
  }

}

export default Faqs

