import {axiosInstance} from '@/services/core/base'
import Core from '@/services/core'

class Users extends Core {
    constructor(axios = axiosInstance) {
        super(axios)
    }

    fetchUsers() {
        return this.get('/api/statistic/subscribers')
    }

}

export default Users

