import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class Disclaimers extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchDisclaimers() {
    return this.get('/api/disclaimers')
  }

  fetchOneDisclaimer(id) {
    return this.get(`/api/disclaimers/${id}`)
  }

  updateDisclaimer(id, body) {
    return this.post(`/api/disclaimers/${id}?_method=PUT`, body)
  }

}

export default Disclaimers

