import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class Subcategories extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchSubcategories() {
    return this.get('/api/sections')
  }

  createNewSubcategory(body) {
    return this.post('/api/sections', body)
  }

  fetchOneSubcategory(id) {
    return this.get(`/api/sections/${id}`)
  }

  updateSubcategory(id, body) {
    return this.post(`/api/sections/${id}`, body)
  }

  deleteSubcategory(id) {
    return this.delete(`/api/sections/${id}`)
  }

}

export default Subcategories

