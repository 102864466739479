import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class faqCategories extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchFaqCategories() {
    return this.get('/api/faqCategories')
  }

  createNewFaqCategory(body) {
    return this.post('/api/faqCategories', body)
  }

  fetchOneFaqCategory(id) {
    return this.get(`/api/faqCategories/${id}`)
  }

  updateFaqCategory(id, body) {
    return this.post(`/api/faqCategories/${id}?_method=PUT`, body)
  }

  deleteFaqCategory(id) {
    return this.delete(`/api/faqCategories/${id}`)
  }

}

export default faqCategories

