import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class Locations extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchLocations() {
    return this.get('/api/locations')
  }

  createNewLocation(body) {
    return this.post('/api/locations', body)
  }

  fetchOneLocation(id) {
    return this.get(`/api/locations/${id}`)
  }

  updateLocation(id, body) {
    return this.post(`/api/locations/${id}?_method=PUT`, body)
  }

  deleteLocation(id) {
    return this.delete(`/api/locations/${id}`)
  }

}

export default Locations

