import { axiosInstance } from '@/services/core/base'
import Core from '@/services/core'

class SendPromo extends Core {
  constructor(axios = axiosInstance) {
    super(axios)
  }

  fetchPromos() {
    return this.get('/api/promos')
  }

  createNewPromo(body) {
    return this.post('/api/promos/sendPromo', body)
  }

}

export default SendPromo

