import Auth from "@/services/auth"
import Settings from '@/services/settings'
import Categories from "@/services/categories";
import Faqs from "@/services/faqs";
import Regions from "@/services/regions";
import Locations from "@/services/locations";
import Feedbacks from "@/services/feedbacks";
import Messages from "@/services/messages";
import Subcategories from "@/services/subcategories";
import faqCategories from "@/services/faqCategories";
import chatLink from "@/services/chatLink";
import SendPromo from "@/services/send-promo";
import AdminsList from "@/services/admins-list";
import Disclaimer from "@/services/disclaimer";
import Users from "@/services/users";


export default {
  auth: new Auth(),
  settings: new Settings(),
  categories: new Categories(),
  faqs: new Faqs(),
  regions: new Regions(),
  locations: new Locations(),
  feedbacks: new Feedbacks(),
  messages: new Messages(),
  subcategories: new Subcategories(),
  faqCategories: new faqCategories(),
  chatLink: new chatLink(),
  SendPromo: new SendPromo(),
  AdminsList: new AdminsList(),
  disclaimer: new Disclaimer(),
  users: new Users(),
}
